import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

import CreateNewPermissionGroupModal from "../../components/model/createNewPermissionGroupModal";
import EditPermissionGroupModal from "../../components/model/editPermissionGroupModal";
import Breadcrumb, { BreadcrumbLink, BreadcrumbItem } from "../../components/ui/Breadcrumb";
import Button from "../../components/ui/Button";
import Modal from "../../components/ui/Modal";

import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { ReactComponent as Plus } from "../../components/ui/styles/plus_icon.svg";
import { ReactComponent as Edit } from "../../components/ui/styles/editIcon.svg";
import { ReactComponent as Delete } from "../../components/ui/styles/icon_Trash.svg";
import { ReactComponent as Setting } from "../../components/ui/styles/Icon_Settings.svg";
import PermissionService from "../../redux/services/permission.service";

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
`;
const WrapperHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
`;
const WrapperHeadBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        background: #ed8154;
        border: 1px solid #ed8154;
        font-size: 14px !important;
        color: #fff;
        text-transform: capitalize;
        min-width: 225px;
        height: 42px;
        font-weight: 700;
        border-radius: 12px;
    }

    .iconButton {
        min-width: 42px;
        margin-right: 15px;

        svg {
            height: 18px;
            width: 18px;
        }
    }
`;

const PermissionTableBody = styled.div`
    padding: 0;
    margin: 0;
    list-style-type: none;
    background: #fff;
    table {
        width: 100%;
        border-collapse: collapse;

        tr {
            border-bottom: 1px solid #ed8154;
        }
        
        tr:hover, tr:focus-within {
            background:rgb(252, 241, 237);
        }

        tbody tr:last-child {
            border-bottom: none;
        }

        td, th {
            border-right: 1px solid #ed8154;
            padding: 4px 15px;
            font-size: 14px;
            color: #999;
            line-height: 22px;
            text-align: left;
            svg,
            img {
            height: 22px;
            width: 22px;
        }
    }
`;

const PermissionTable = styled.div`
    background: #fff;
    border-radius: 0;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
    border: 1px solid #ed8154;
    max-height: calc(100vh - 195px);
    overflow: auto;
`;

const ListActionBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    button {
        height: 26px;
        width: 26px;
        background: #fff;
        border: 2px solid #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer important;

        &:hover {
            background:rgb(253, 225, 215);
            border-color: #ed8154;
        }
        
        svg {
            pointer-events: none;
            path {
                fill: #ed8154;
            }
        }
        
        &:last-child svg path {
            fill: #982727;
        }
    }
`;

const PermissionGroup = () => {
    const navigate = useNavigate();

    const [createNewPermission, setCreateNewPermission] = useState(false);
    const [editPermissionGroup, setEditPermissionGroup] = useState(false);
    const [editPermissionGroupData, setEditPermissionGroupData] = useState();
    const [permissionGroupList, setPermissionGroupList] = useState(false);

    // const permissionGroupList = useSelector((state) => state.Permissions?.permissionGroupList);

    const fetchData = async () => {
        const response = await PermissionService.fetchPermissionGroups();

        if (response.isSuccess) {
            setPermissionGroupList(response.data);
        } else {
            setPermissionGroupList([]);
        }
    }

    useEffect(() => { fetchData(); }, []);

    const onPermissionGroupEdit = event => {
        const permissionId = event.target.dataset.permissionId;

        if (permissionId) {
            setEditPermissionGroup(true);
            setEditPermissionGroupData({
                id: permissionId,
                name: event.target.dataset.permissionName,
                description: event.target.dataset.permissionDescription,
            });
        }
    }

    const onPermissionGroupNavigate = event => {
        const permissionId = event.target.dataset.permissionId;

        if (permissionId) {
            navigate(`/company-settings/permission-group/permission/${permissionId}`/*, { state: { data: permission } }*/);
        }
    }

    const onPermissionGroupDelete = async event => {
        const permissionId = event.target.dataset.permissionId;

        if (permissionId) {
            await PermissionService.deletePermissionGroup(permissionId)
                .then(() => fetchData());
            // dispatch(deletePermissionGroup(permissionId));
        }
    }

    return (
        <Wrapper>
            <WrapperHead>
                <Breadcrumb primary>
                    <BreadcrumbLink to={"/home"}><BreadcrumbItem children={<HomeIcon />} /></BreadcrumbLink>
                    <BreadcrumbLink to={"/company-settings/overview"}><BreadcrumbItem children={"Setting"} /></BreadcrumbLink>
                    <BreadcrumbLink to={"#"/*`/permission-group`*/}><BreadcrumbItem children={"Permission Group"} /></BreadcrumbLink>
                </Breadcrumb>
                <WrapperHeadBtn>
                    <Button
                        className="iconButton"
                        onClick={() => setCreateNewPermission(true)}
                    >
                        <Plus />
                    </Button>
                </WrapperHeadBtn>
            </WrapperHead>
            <PermissionTable>
                <PermissionTableBody>
                    <React.Fragment>
                        <table>
                            <thead>
                                <tr>
                                    <th>Group Name</th>
                                    <th>Member</th>
                                    <th>Description</th>
                                    <th>Access To</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissionGroupList?.map?.(
                                    permission => (
                                        <PermissionGroupRow
                                            permission={permission}
                                            onEdit={onPermissionGroupEdit}
                                            onNavigate={onPermissionGroupNavigate}
                                            onDelete={onPermissionGroupDelete}
                                            key={permission._id}
                                        />
                                    )
                                )}
                            </tbody>
                        </table>
                    </React.Fragment>
                </PermissionTableBody>
            </PermissionTable>
            <Modal show={createNewPermission}>
                <CreateNewPermissionGroupModal
                    setCreateNewPermission={setCreateNewPermission}
                    fetchData={fetchData}
                />
            </Modal>
            <Modal show={editPermissionGroup}>
                <EditPermissionGroupModal
                    setEditPermissionGroup={setEditPermissionGroup}
                    editPermissionGroupData={editPermissionGroupData}
                    fetchData={fetchData}
                />
            </Modal>
        </Wrapper>
    );
};

const PermissionGroupRow = ({ permission, onEdit, onNavigate, onDelete }) => {
    return (
        <tr key={permission._id}>
            <td>{permission.name}</td>
            <td>{permission.member}</td>
            <td>
                {permission.description}
            </td>
            <td>{generatePermissionString(permission.access)}</td>
            <td>
                <ListActionBtn>
                    <button
                        onClick={onEdit}
                        data-permission-id={permission._id}
                        data-permission-name={permission.name}
                        data-permission-description={permission.description}
                    >
                        <Edit />
                    </button>
                    <button onClick={onNavigate} data-permission-id={permission._id}><Setting /></button>
                    <button onClick={onDelete} data-permission-id={permission._id}><Delete /></button>
                </ListActionBtn>
            </td>
        </tr>
    )
}

const generatePermissionString = access => {
    const accessList = [];

    if (access?.projects) {
        accessList.push("Projects");

        if (access?.projectsGeneralSettings) {
            accessList.push("Projects General Settings");
        }
        
        if (access?.projectsDelete) {
            accessList.push("Delete Projects");
        }
    }
    if (access?.surveys) {
        accessList.push("Surveys");

        if (access?.surveyDesigner) {
            accessList.push("Survey Designer");
        }
    }
    if (access?.scheduler) { accessList.push("Scheduler"); }
    if (access?.panels) { accessList.push("Panels"); }
    if (access?.contacts) { accessList.push("Contacts"); }
    if (access?.defaults) { accessList.push("Defaults"); }
    if (access?.settings) { accessList.push("Settings"); }

    return accessList.join(", ");
}

export default PermissionGroup;
