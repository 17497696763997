import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeCard from "../../components/homecard";
import { AllProjects } from "../../constant/allProjectData";
import { fetchGeneralPermissions } from "../../redux/features/PermissionSlice";

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 180px;
    margin-right: 180px;
    padding: 30px;
    box-sizing: border-box;
    .overflowHidden {
        overflow: hidden;
        & > div > div:last-child {
            min-height: 120px;
            p {
                line-height: 22px;
            }
        }
    }
`;

const ProjectAllCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    & > div {
        overflow: initial;
    }
`;

const ProjectBlock = styled.div`
    .smallTitle {
        font-size: 16px !important;
        color: #666 !important;
        padding: 0;
        text-align: left;
        margin: 0 0 25px;
        font-weight: 700 !important;
    }
`;

const Home = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchGeneralPermissions());
    }, []);

    const modulePermissions = useSelector((state) => state.Permissions?.permissions);

    return (
        <React.Fragment>
            <Wrapper>
                <ProjectBlock>
                    <h4 className="smallTitle">Welcome to Q One</h4>
                    <ProjectAllCards>
                        {AllProjects?.map((cardData, index) => {
                            if (!modulePermissions?.[cardData.permission]) {
                                return null;
                            }

                            return (
                                <HomeCard
                                    key={index}
                                    title={cardData?.title}
                                    paragraph={cardData?.paragraph}
                                    path={cardData.path}
                                    className="overflowHidden"
                                    pathname={cardData.path}
                                    buttonName="Go"
                                // onClick={() => navigate(cardData.path)}
                                ></HomeCard>
                            );
                        })}
                    </ProjectAllCards>
                </ProjectBlock>
            </Wrapper>
        </React.Fragment>
    );
};

export default Home;
