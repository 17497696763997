import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    const isAuthenticated = token && token != undefined

    return isAuthenticated ? (children) : (<Navigate to="/login" />);
};

export default PrivateRoute;
