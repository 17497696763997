import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { InvitedUserCardActions, SoftDeletedUserCardActions, UserCardActions } from "../../constant/usermanagement";
import UserCardInfo from "../../components/userCardInfo";
import InviteNewUser from "../../components/model/inviteNewUser";
import SelectPermission from "../../components/model/selectPermission";
import { deleteInviteUser, deleteUser, fetchAllInvitedUsers, fetchAllUsers, userRestPassword } from "../../redux/features/UserManagementSlice";
import { fetchPermissionGroups } from "../../redux/features/PermissionSlice";
import { createUser } from "../../redux/features/UserManagementSlice";
import DeleteConfirmationModal from "../../components/ui/DeleteConfirmationModal";

import BreadCrumb, { BreadcrumbItem, BreadcrumbLink } from "../../components/ui/Breadcrumb";
import { SearchDropDown } from "../../components/ui/Dropdown";
import Input from "../../components/ui/Input";
import Modal from "../../components/ui/Modal";

import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { ReactComponent as SearchIcon } from "../../components/ui/styles/search.svg";
// import { ReactComponent as Settings } from "../../components/ui/styles/settings.svg";

const WrapperHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
`;

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #e4e4e4;
`;

const FilterButton = styled.button`
    background: #ed8154;
    border: 1px solid #ed8154;
    font-size: 14px !important;
    color: #fff;
    text-transform: capitalize;
    min-width: 42px;
    height: 42px;
    font-weight: 700;
    border-radius: 12px;
    cursor: pointer !important;
`;

const CardSearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
        width: calc(100% - 40px);
        input {
            padding: 10px;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #bbb;
            border-radius: 10px 0 0 10px;
            height: 40px;
            font-size: 15px;
            color: #666;
            font-weight: 400;
            &:focus {
                border: 1px solid #bbb;
                box-shadow: none;
                outline: none;
            }
        }
    }
    button {
        width: 40px;
        height: 40px;
        background: #ed8154;
        border: 1px solid #ed8154;
        border-radius: 0 10px 10px 0;
        padding: 10px;
        cursor: pointer;
    }
`;
// const CheckBox = styled.div`
// text-align: left;
// input {
// display none
// }
// label {
// font-size: 14px !important;
// color: #666 !important;
// margin: 0;
// width: 100%;
// text-align: left;
// position: relative;
// display: flex;
// align-items: center;
// cursor: pointer;
// gap: 8px;
// &:before {
//   content: '';
//   height: 15px;
//   width: 15px;
//   border: 2px solid #BDBDBD;
//   border-radius: 3px;
//   background: #fff;
//   display: inline-block;
//   // margin-right: 12px;
//   flex-grow: 0;
//   flex-shrink: 0;
//   flex-basis: 15px;
// }
// &:after {
//   content: '';
//   height: 5px;
//   width: 9px;
//   border-left: 2px solid #fff;
//   border-bottom: 2px solid #fff;
//   position: absolute;
//   left: 4px;
//   transform: rotate(-45deg);
//   margin-bottom: 4px;
// }
// }
// input:checked + label:before {
// background: #ED8154;
// border: 2px solid #ED8154;
// }
// `;
const WrapperHeadBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    & > button {
        background: #ed8154;
        border: 1px solid #ed8154;
        font-size: 14px !important;
        color: #fff;
        text-transform: capitalize;
        border-radius: 8px;
        font-weight: 500;
        outline: 0;
        cursor: pointer !important;
        font-family: "Roboto";
        min-width: 200px;
        height: 40px;

        // &:hover {
        //   background: #fff;
        //   color: #ed8154;
        // }
    }
    input {
        border-color: #fff !important;
    }
`;
// const BulkActionData = styled.div`
//   padding: 20px 0;
// `;
// const BulkActionDataDropdown = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0px;
//   svg path {
//     color: #ed8154;
//     fill: #ed8154;
//   }
// `;
// const BulkActionDataHeader = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   gap: 32px;
//   select {
//     background: transparent;
//     color: #ed8154;
//     width: 125px;
//   }
// `;

const ProjectAllCards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    & > div {
        overflow: initial;
    }
`;

const ProjectBlock = styled.div`
    .smallTitle {
        font-size: 16px !important;
        color: #666 !important;
        padding: 0;
        text-align: left;
        margin: 0 0 25px;
        font-weight: 700 !important;
    }
`;

const ProjectInfoBox = styled.div`
    display: flex;
    width: 100%;
    ul {
        padding: 15px;
        margin: 0;
        list-style-type: none;
        border-right: 1px solid #ed8154;
        width: 69%;
        box-sizing: border-box;
        background: #fcf6ef;
        border-radius: 0 0 0 10px;
        li {
            font-size: 14px !important;
            color: #666 !important;
            line-height: 20px !important;
            margin: 0 0 10px;
            text-align: left;
            display: flex;
            line-break: anywhere;
            &:last-child {
                margin: 0;
            }
            span {
                font-weight: 600;
                width: 125px;
                display: inline-block;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 125px;
            }
        }
    }
`;

const ProjectSelectBox = styled.div`
    width: 31%;
    box-sizing: border-box;
    padding: 12px;
    ul.gmhWhB {
        li {
            &:last-child {
                color: #9a2a2a !important;
            }
        }
    }
    & > div > div {
        margin: 0 0 10px;
        & > div:first-of-type {
            border: none;
            padding: 0;
            border-radius: 0;
            height: 20px;
            font-size: 14px;
            color: #ed8154;
            font-weight: 600;
            margin: 0;
            svg {
                right: 6px;
                top: 6px;
                height: 12px;
                width: 12px;
                color: #ed8154;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 12px;
                path {
                    color: #ed8154;
                    fill: #ed8154;
                }
            }
        }

        div {
            ul {
                padding: 0;
                margin: 0;
                border-right: none;
                width: 100%;
                background: #fff;
            }
        }

        &:last-child {
            margin: 0;
            & > div {
                color: #ed8154;
                font-weight: 500;
                svg {
                    path {
                        fill: #ed8154;
                    }
                }
                li:last-child {
                    color: #9a2a2a !important;
                }
            }
        }
        &:nth-child(1) {
            z-index: 6 !important;
        }
        &:nth-child(2) {
            z-index: 5 !important;
        }
        &:nth-child(3) {
            z-index: 4 !important;
        }
        &:nth-child(4) {
            z-index: 3 !important;
        }
        &:nth-child(5) {
            z-index: 2 !important;
        }
        &:nth-child(6) {
            z-index: 1 !important;
        }
    }
    & > div {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        // margin: 0 0 8px;
        // cursor: pointer;
        select {
            width: 100%;
            padding: 0;
            font-size: 14px;
            color: #ed8154;
            font-weight: 600;
            margin: 0 0 8px;
            &:last-child {
                margin: 0 0 8px;
                color: #9a2a2a;
            }
        }
        p {
            font-size: 14px;
            color: #ed8154;
            font-weight: 600;
            margin: 0;
        }
        svg {
            height: 12px;
            width: 12px;
            color: #ed8154;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 12px;
            path {
                color: #ed8154;
                fill: #ed8154;
            }
        }
        &:last-child {
            margin: 0;
            p {
                color: #9a2a2a;
            }
            svg {
                path {
                    fill: #9a2a2a;
                }
            }
        }
    }
`;
const Switch = styled.label`
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }
    input:checked + .slider {
        background-color: #ed8154;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
    }
    input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
`;
const SwitchBox = styled.span`
    // margin: 0 -12px 15px;
    // padding: 0 12px 12px;
    // border-bottom: 1px solid #ed8154;
    display: flex;
    align-items: center;
    gap: 6px;
    // justify-content: flex-end;
    & > label {
        font-size: 14px !important;
        color: #ed8154 !important;
        line-height: 20px !important;
    }
`;

export const items = [
    { id: 1, to: "/home", Icon: HomeIcon },
    { id: 2, to: "/company-settings/overview", label: "Settings" },
    { id: 3, to: "/user-management-dashboard", label: "User management" },
];

const UserManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const allUsersData1 = useSelector((state) => state.Users.allUserData);
    const allInvitedUserData1 = useSelector((state) => state.Users.invitedUsers);
    const permissionGroupList = useSelector(state => state.Permissions.permissionGroupList);
    const [inviteUserModal, setInviteUserModal] = useState(false);
    const [selectPermissionModal, setSelectPermissionModal] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const activeUser = JSON.parse(localStorage.getItem("user"));
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteUserData, setDeleteUserData] = useState("");

    const [permissionUser, setPermissionUser] = useState("");

    const [allUserData, setAllUserData] = useState([]);

    // let allUserData = [...allUsersData1, ...allInvitedUserData1];

    useEffect(() => {
        dispatch(fetchAllUsers());
        dispatch(fetchPermissionGroups());
        dispatch(fetchAllInvitedUsers());
    }, [dispatch]);

    useEffect(() => {
        if (allUsersData1.length > 0 && allInvitedUserData1.length > 0) {
            const allUserEmailsSet = new Set(allUsersData1.map(userData => userData.email));
            const filteredInvitedUsers = allInvitedUserData1.filter(invitedUser =>
                !allUserEmailsSet.has(invitedUser.email)
            );

            const uniqueUserData = [...filteredInvitedUsers.reverse(), ...allUsersData1];
            setAllUserData(uniqueUserData);
        } else if (allUsersData1.length > 0) {
            setAllUserData([...allUsersData1]);
        } else if (allInvitedUserData1.length > 0) {
            setAllUserData([...allInvitedUserData1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, allUsersData1, allInvitedUserData1]);

    const handleUserActions = (option, user) => {
        if (option.name === "Permission Group") {
            setSelectPermissionModal(true);
            setPermissionUser(user);
        }
        if (option.name === "Reset User Password") {
            //if condition
            dispatch(userRestPassword({ ...user, password: "" }));
            alert("user password rest");
        }
        if (option.name === "Delete User") {
            setDeleteUserData(user);
            setDeleteModal(true);
        }
        if (option.name === "Reinvite User") {
            const { name, email, permissionGroupId, _id } = user;
            const newUser = {
                name: name,
                email: email,
                permissionGroupId: permissionGroupId,
                companyId: 1,
            };

            dispatch(createUser(newUser));
            dispatch(deleteUser(_id));
        }
        if (option.name === "Delete Invited User") {
            dispatch(deleteInviteUser(user._id));
        }
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteUser(deleteUserData._id));
        setDeleteUserData("");
        setDeleteModal(false);
    };

    const searchFilter = user => {
        return `${user.name}${user.email}`.includes(searchValue.toLowerCase());
    }

    const permissionGroupMap = {};

    for (let i = 0; i < permissionGroupList?.length; i++) {
        const permisionGroup = permissionGroupList[i];
        permissionGroupMap[permisionGroup._id] = permisionGroup;
    }

    return (
        <>
            <Wrapper>
                <WrapperHead>
                    <BreadCrumb primary>
                        <BreadcrumbLink to={"/home"}><BreadcrumbItem children={<HomeIcon />} /></BreadcrumbLink>
                        <BreadcrumbLink to={"/company-settings/overview"}><BreadcrumbItem children={"Setting"} /></BreadcrumbLink>
                        <BreadcrumbLink to={""}><BreadcrumbItem children={"User management"} /></BreadcrumbLink>
                    </BreadCrumb>
                    <WrapperHeadBtn>
                        <FilterButton onClick={() => setInviteUserModal(true)}>
                            + Invite new user
                        </FilterButton>
                        <FilterButton onClick={() => navigate("/company-settings/user-management-dashboard/roles")}>
                            Manage Roles
                        </FilterButton>
                        <CardSearch>
                            <Input
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="Enter search term"
                            />
                            <button>
                                <SearchIcon />
                            </button>
                        </CardSearch>
                    </WrapperHeadBtn>
                </WrapperHead>
                {/* <BulkActionData>
          <BulkActionDataHeader>
            <CheckBox>
              <input type="checkbox" name={"id"} id={"id"} />
              <label htmlFor={"id"}>Select All</label>
            </CheckBox>
            <BulkActionDataDropdown>
              <Settings />
              <Dropdown defaultValue="Bulk Actions" name={BulkActions} />
            </BulkActionDataDropdown>
          </BulkActionDataHeader>
        </BulkActionData> */}
                <ProjectBlock>
                    <ProjectAllCards>
                        {allUserData && allUserData?.filter(searchFilter).map(
                            user => (
                                <UserCard
                                    user={user}
                                    permissionGroupMap={permissionGroupMap}
                                    handleUserActions={handleUserActions}
                                    activeUser={activeUser}
                                    key={user._id}
                                />
                            )
                        )}
                    </ProjectAllCards>
                </ProjectBlock>
            </Wrapper>

            <Modal show={inviteUserModal}>
                <InviteNewUser setInviteUser={setInviteUserModal} />
            </Modal>

            <Modal show={selectPermissionModal}>
                <SelectPermission
                    setSelectPermissionModal={setSelectPermissionModal}
                    permissionUser={permissionUser}
                    setPermissionUser={setPermissionUser}
                />
            </Modal>

            <Modal show={deleteModal}>
                <DeleteConfirmationModal
                    handleDeleteConfirm={handleDeleteConfirm}
                    message={`Are you sure you want to delete user ${deleteUserData.email} ?`}
                    setCloseModal={setDeleteModal}
                />
            </Modal>
        </>
    );
};

const UserCard = ({ user, permissionGroupMap, activeUser, handleUserActions }) => {
    if (user.email === activeUser?.email) {
        // return null;
    }

    return (
        <UserCardInfo
            title={<>{user.name}</>}
            verified={user.password ? (<>Verified</>) : ("")}
            invited={!user.password ? (<>Invited</>) : ("")}
            deleted={user.isDeleted === "true" ? (<>Deleted</>) : ("")}
        >
            <ProjectInfoBox>
                <ul>
                    <li>
                        <span>
                            Permission:{" "}
                        </span>
                        {permissionGroupMap[user.permissionGroupId]?.name || "Administration"}
                    </li>
                    <li>
                        <span>Email </span>{" "}{user.email}
                    </li>
                </ul>

                <ProjectSelectBox>
                    {/* <div>
                            <SwitchBox>
                              <label htmlFor="checkStatus">Enable</label>
                              <Switch>
                                <input type="checkbox" id="checkStatus" />
                                <span className="slider round"></span>
                              </Switch>
                            </SwitchBox>
                          </div> */}
                    <div>
                        <SearchDropDown
                            name={
                                user?.isDeleted === "true"
                                    ? SoftDeletedUserCardActions
                                    : user?.password
                                        ? UserCardActions
                                        : InvitedUserCardActions
                            }
                            handleDropdownActions={(name) => { handleUserActions(name, user); }}
                            defaultValue="Actions"
                        />
                    </div>
                </ProjectSelectBox>
            </ProjectInfoBox>
        </UserCardInfo>
    )
}

export default UserManagement;
