import axios from "axios";
import { testResponseAuth } from "../../utils";

const baseURL = process.env.REACT_APP_BACKEND_SERVER;

const BearerToken = localStorage.getItem("token");
const config = {
    headers: {
        Authorization: `Bearer ${BearerToken}`,
        // Permission: `Company Settings`,
    },
};

export async function fetchPermissionGroup(permisionGroupId) {
    try {
        const response = await axios.get(`${baseURL}/permission-groups/${permisionGroupId}`, config);
        return { isSuccess: true, data: response.data };
    } catch (e) {
        testResponseAuth(e.response);
        return {
            isSuccess: false,
            status: e.response.staus,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

export async function fetchPermissionGroups() {
    try {
        const response = await axios.get(`${baseURL}/permission-groups`, config);
        return { isSuccess: true, data: response.data };
    } catch (e) {
        testResponseAuth(e.response);
        return {
            isSuccess: false,
            status: e.response.staus,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

export async function createPermissionGroup(addPermissionGroup) {
    try {
        const response = await axios.post(`${baseURL}/permission-groups`, { ...addPermissionGroup }, config);
        return { isSuccess: true, data: response.data };
    } catch (e) {
        testResponseAuth(e.response);
        return {
            isSuccess: false,
            status: e.response.staus,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

export async function fetchAllPermissions() {
    try {
        const response = await axios.get(`${baseURL}/permissions`, config);
        return { isSuccess: true, data: response.data };
    } catch (e) {
        testResponseAuth(e.response);
        return {
            isSuccess: false,
            status: e.response.staus,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

export async function editPermissionGroup(id, editData) {
    try {
        const response = await axios.put(`${baseURL}/permission-groups/${id}`, editData, config);
        return { isSuccess: true, data: response.data };
    } catch (e) {
        testResponseAuth(e.response);
        return {
            isSuccess: false,
            status: e.response.staus,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

export async function deletePermissionGroup(id) {
    try {
        const response = await axios.delete(`${baseURL}/permission-groups/${id}`, config);
        return { isSuccess: true, data: response.data };
    } catch (e) {
        testResponseAuth(e.response);
        return {
            isSuccess: false,
            status: e.response.staus,
            error:
                e.response.data.Error ||
                e.response.data.error ||
                e.response.data.err.message,
        };
    }
}

const PermissionService = {
    fetchPermissionGroups,
    fetchPermissionGroup,
    fetchAllPermissions,
    createPermissionGroup,
    editPermissionGroup,
    deletePermissionGroup,
};

export default PermissionService;
