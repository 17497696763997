import React, { useEffect, useState } from "react";
import BreadCrumb, { BreadcrumbItem, BreadcrumbLink, } from "../../components/ui/Breadcrumb";
import styled from "styled-components";
import Card from "../../components/card/index";
import CardContent from "../../components/cardcontent";
import Dropdown from "../../components/ui/Dropdown";

import { legalSecurityStaticData } from "../../constant/legal-security/index";
import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import CompanySettingService from "../../redux/services/companySetting.service";
// import { useDispatch } from "react-redux";
// import { fetchAllLegalSecurity } from "../../redux/features/LegalSecuritySlice";

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
`;

const CardWrapper = styled.div`
    display: Grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
`;

const ContentModule = styled.div`
    box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 10%);
    background: #fff;
    padding: 25px;
    border-radius: 8px;
    text-align: left;
    height: calc(100vh - 200px);
    overflow: auto;
    margin-top: 25px;
    h1 {
        font-size: 22px;
        color: #000;
        margin: 0 0 25px;
    }
    h2 {
        font-size: 16px;
        color: #000;
        margin: 30px 0 12px;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        color: #888;
        margin: 10px 24px 12px;
    }
`;

const WrapperHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    select {
        border: 1px solid #fff;
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        height: 40px;
        font-size: 15px;
        color: #666;
        font-weight: 600;
        max-width: 300px;
    }
`;

const ContentModuleTitle = styled.h6`
    margin: 0 0 20px;
    font-size: 20px;
    color: #666;
    font-weight: bold;
    margin: 0 0 20px;
`;

const ContentModuleText = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 14px;
    line-height: 24px;
    color: #666;
`;

const LegalSecurity = () => {
    // const navigate = useNavigate();
    const [viewPath, setViewPath] = useState();
    const [isView, setIsView] = useState(false);

    const [items] = useState([
        { id: 1, to: "/home", Icon: HomeIcon },
        { id: 2, to: "/company-settings/overview", label: "Settings" },
        {
            id: 3,
            to: "/company-settings/legal-and-security",
            label: "Legal & Security",
        },
    ]);

    const handleClick = (id) => {
        if (id !== items.length) {
            setIsView(false);
        }
        if (id <= items.length) {
            items.length = id;
        }
    };

    useEffect(() => {
        const fetchSettingsAuth = async () => await CompanySettingService.fetchCompanyAuth();
        fetchSettingsAuth();
    }, []);

    return (
        <Wrapper>
            <WrapperHead>
                <BreadCrumb primary>
                    {items?.map(({ to, label, Icon, id }, index) => (
                        <BreadcrumbLink key={index} to={to}>
                            <BreadcrumbItem
                                children={Icon ? <Icon /> : label}
                                onClick={() => handleClick(id)}
                            />
                        </BreadcrumbLink>
                    ))}
                </BreadCrumb>

                {isView ? (
                    <React.Fragment>
                        {legalSecurityStaticData
                            .filter((x) => x.path === viewPath)
                            .map((cardData, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {cardData.options.length > 0 ? (
                                            <Dropdown
                                                defaultValue="Select One"
                                                name={cardData.options}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                );
                            })}
                    </React.Fragment>
                ) : null}
            </WrapperHead>

            {!isView ? (
                <CardWrapper>
                    {legalSecurityStaticData?.map((cardData, index) => (
                        <React.Fragment key={index}>
                            <Card
                                title={cardData?.title}
                                paragraph={cardData?.paragraph}
                                path={cardData.path}
                                buttonName="view"
                                onClick={() => {
                                    setIsView(true);
                                    setViewPath(cardData.path);
                                    items.push({
                                        id: items.length + 1,
                                        to: cardData.path,
                                        label: cardData.title,
                                    });
                                }}
                            >
                                <li>
                                    <span>Last Update: </span> {cardData.lastDate}
                                </li>
                                {/* {cardData.dateSigned !== "" ? (
                  <li>
                    <span
                      style={{
                        fontWeight: 600,
                        width: 125,
                        display: "inline-block",
                      }}
                    >
                      Data Signed :
                    </span>
                    {cardData.dateSigned}
                  </li>
                ) : null}
                {cardData.signedBy !== "" ? (
                  <li>
                    <span
                      style={{
                        fontWeight: 600,
                        width: 125,
                        display: "inline-block",
                      }}
                    >
                      Signed by :
                    </span>
                    {cardData.signedBy}
                  </li>
                ) : null} */}
                            </Card>
                        </React.Fragment>
                    ))}
                </CardWrapper>
            ) : (
                <ContentModule>
                    {legalSecurityStaticData
                        .filter((x) => x.path === viewPath)
                        .map((cardData, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {/* <ContentModuleTitle>{title}</ContentModuleTitle> */}
                                    <ContentModuleText>{cardData.description}</ContentModuleText>
                                </React.Fragment>
                            );
                        })}
                </ContentModule>
            )}
        </Wrapper>
    );
};

export default LegalSecurity;
