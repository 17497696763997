import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PermissionService from "../services/permission.service";

const initialState = {
    permissionGroupData: {},
    permissionGroupList: [],
    permissions: {},
    userName: "",
    isFetching: false,
    isSuccess: false,
    error: false,
};

export const fetchGeneralPermissions = createAsyncThunk(
    "permission/fetchGeneralPermissions",
    async (_thunkAPI) => {
        const response = await PermissionService.fetchAllPermissions();
        return response;
    }
);

export const fetchPermissionGroup = createAsyncThunk(
    "permission/fetchPermissiongroup",
    async (permissionGroupId, _thunkAPI) => {
        const response = await PermissionService.fetchPermissionGroup(permissionGroupId);
        return response;
    }
);

export const fetchPermissionGroups = createAsyncThunk(
    "permission/fetchPermissiongroups",
    async (_thunkAPI) => {
        const response = await PermissionService.fetchPermissionGroups();
        return response;
    }
);

export const createPermissionGroup = createAsyncThunk(
    "permission/createPermissionGroup",
    async (permissionGroupData, _thunkAPI) => {
        await PermissionService.createPermissionGroup(permissionGroupData);
        const response = await PermissionService.fetchPermissionGroups();
        return response;
    }
);

export const editPermissionGroup = createAsyncThunk(
    "permission/editPermissionGroup",
    async ({ id, ...data }, _thunkAPI) => {
        await PermissionService.editPermissionGroup(id, data);
        const response = await PermissionService.fetchPermissionGroup(id);
        return response;
    }
);

export const deletePermissionGroup = createAsyncThunk(
    "permission/deletePermissionGroup",
    async (id, _thunkAPI) => {
        await PermissionService.deletePermissionGroup(id);
        const response = await PermissionService.fetchPermissionGroups();
        return response;
    }
);

const PermissionSlice = createSlice({
    name: "permission",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGeneralPermissions.fulfilled, (state, action) => {
            const { data } = action.payload.data;
            state.permissions = data.permissions;
            state.userName = data.userName;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(fetchGeneralPermissions.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchGeneralPermissions.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(fetchPermissionGroup.fulfilled, (state, action) => {
            const { data } = action.payload.data;
            state.permissionGroupData = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(fetchPermissionGroup.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchPermissionGroup.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(fetchPermissionGroups.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.permissionGroupList = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(fetchPermissionGroups.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(fetchPermissionGroups.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(createPermissionGroup.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.permissionGroupData = data;
            state.permissionGroupList = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(createPermissionGroup.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(createPermissionGroup.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(editPermissionGroup.fulfilled, (state, action) => {
            const { data } = action.payload.data;
            state.permissionGroupData = data;
            state.permissionGroupList = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(editPermissionGroup.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(editPermissionGroup.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });

        builder.addCase(deletePermissionGroup.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.permissionGroupData = data;
            state.permissionGroupList = data;
            state.isFetching = false;
            state.isSuccess = true;
            state.error = false;
        });
        builder.addCase(deletePermissionGroup.pending, (state) => {
            state.isSuccess = false;
            state.isFetching = true;
            state.error = false;
        });
        builder.addCase(deletePermissionGroup.rejected, (state) => {
            state.isSuccess = false;
            state.isFetching = false;
            state.error = true;
        });
    },
});

export default PermissionSlice;
