import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
    const navigate = useNavigate();
    const [time, setTime] = useState(5);

    useEffect(() => {
        setTime(5);
    }, []);

    useEffect(() => {
        setInterval(() => {
            if (time === 0) {
                navigate("/", { replace: true });
            } else {
                setTime(time - 1);
            }
        }, 1000);
    }, [time]);

    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "100px",
        }}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="110.889"
                height="16.887"
                viewBox="0 0 110.889 16.887"
                style={{ transform: "scale(200%)", marginBottom: "50px" }}
            >
                <path
                    d="M39.51,23.35a8.817,8.817,0,0,1-.654,3.555,5.49,5.49,0,0,1-1.836,2.324l2.539,1.992L38.279,32.4l-3-2.383A6.1,6.1,0,0,1,33.8,30.2a5.458,5.458,0,0,1-2.979-.82A5.522,5.522,0,0,1,28.8,27.021a8.524,8.524,0,0,1-.732-3.545V22.451a8.692,8.692,0,0,1,.713-3.623,5.28,5.28,0,0,1,5-3.242,5.481,5.481,0,0,1,3.018.83,5.339,5.339,0,0,1,2.012,2.393,8.735,8.735,0,0,1,.7,3.633Zm-1.875-.918a6.406,6.406,0,0,0-1.006-3.867,3.6,3.6,0,0,0-5.644-.01A6.243,6.243,0,0,0,29.94,22.3V23.35A6.457,6.457,0,0,0,30.955,27.2a3.616,3.616,0,0,0,5.654.088,6.373,6.373,0,0,0,1.025-3.8ZM58.3,23.35A8.83,8.83,0,0,1,57.6,27,5.33,5.33,0,0,1,55.6,29.375,5.5,5.5,0,0,1,47.6,27.021a8.524,8.524,0,0,1-.732-3.545V22.451a8.691,8.691,0,0,1,.713-3.623,5.593,5.593,0,0,1,8.008-2.412A5.271,5.271,0,0,1,57.6,18.809a8.8,8.8,0,0,1,.7,3.643Zm-1.865-.918a6.352,6.352,0,0,0-1.016-3.877,3.613,3.613,0,0,0-5.645,0,6.244,6.244,0,0,0-1.045,3.75V23.35a6.43,6.43,0,0,0,1.025,3.857,3.624,3.624,0,0,0,5.654.078,6.372,6.372,0,0,0,1.025-3.8ZM72.039,30H70.154L63,19.043V30H61.111V15.781H63l7.178,11.006V15.781h1.865Zm11.367-6.572H77.244v5.039H84.4V30H75.369V15.781H84.3v1.543H77.244v4.57h6.162Z"
                    transform="translate(-28.065 -15.516)"
                    fill="#ed8154"
                />
                <path
                    d="M101.521,17.324h-4.57V30H95.086V17.324H90.525V15.781h11Zm10.147,6.1h-6.162v5.039h7.158V30h-9.033V15.781h8.935v1.543h-7.06v4.57h6.162Zm13.789,2.061a5.239,5.239,0,0,1-1.67,3.486,5.452,5.452,0,0,1-3.721,1.221,5.026,5.026,0,0,1-4.043-1.807,7.285,7.285,0,0,1-1.513-4.834V22.188a8.146,8.146,0,0,1,.7-3.486,5.286,5.286,0,0,1,2.012-2.3,5.558,5.558,0,0,1,3.007-.811,5.124,5.124,0,0,1,3.633,1.27,5.273,5.273,0,0,1,1.592,3.5h-1.885a4.045,4.045,0,0,0-1.064-2.461,3.2,3.2,0,0,0-2.276-.762,3.37,3.37,0,0,0-2.822,1.328,6.115,6.115,0,0,0-1.015,3.779v1.377a6.267,6.267,0,0,0,.966,3.682,3.126,3.126,0,0,0,2.705,1.367,3.605,3.605,0,0,0,2.393-.7,3.935,3.935,0,0,0,1.113-2.471ZM138.953,30h-1.885V23.428H129.9V30h-1.875V15.781H129.9v6.113h7.168V15.781h1.885Z"
                    transform="translate(-28.065 -15.586)"
                    fill="#4f4f4f"
                />
            </svg>

            <div style={{ fontSize: "28px", padding: "50px 100px", textAlign: "center", color: "#4f4f4f" }}>
                Unauthorized... redirecting
                <button
                    onClick={() => window.location = "/"}
                    style={{
                        margin: "5px",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "28px",
                        color: "#ed8154",
                    }}
                >
                    to Home page
                </button>
                in {time}s.
            </div>
        </div>
    )
}

export default Unauthorized;