import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";

import Breadcrumb, { BreadcrumbLink, BreadcrumbItem } from "../../components/ui/Breadcrumb";
import { editPermissionGroup, fetchPermissionGroup, } from "../../redux/features/PermissionSlice";

import { ReactComponent as HomeIcon } from "../../components/ui/styles/home.svg";
import { ReactComponent as DownIcon } from "../../components/ui/styles/DownArrow.svg";

const Wrapper = styled.section`
    min-height: calc(100vh - 58px);
    margin-left: 250px;
    padding: 30px;
    box-sizing: border-box;
`;

const WrapperHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
`;

const PermissionTable = styled.div`
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
    overflow: hidden;
    max-height: calc(100vh - 195px);
    display: flex;
    flex-direction: column;
`;

const UserBlockList = styled.div`
    position: sticky;
    padding: 16px;
    color: #828282;
    span {
        color: #417ac7;
        margin-left:5px;
        font-weight: 500;
    }
`;

const DataTable = styled.div`
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;

    tr {
      border-bottom: 1px solid #ed8154;
    }
      
    tr:hover, tr:focus-within {
      background:rgb(252, 241, 237);
    }

    tbody {
      tr:last-child {
        border-bottom: none;
      }
      td:last-child {
        color: #333;
      }
    }
    td,
    th {
      border-right: 1px solid #ed8154;
      padding: 4px 15px;
      font-size: 14px;
      color: #828282;
      line-height: 22px;
      text-align: left;
      white-space: nowrap;
      width: 100%;
      svg,
      img {
        height: 22px;
        width: 22px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
`;

const ShowIf = ({ condition, children, ifTrue, ifFalse }) => {
    if (!condition) {
        return ifFalse;
    }

    if (ifTrue !== undefined) {
        return ifTrue;
    }

    return children;
};

const Select = styled.select`
    min-width: 300px;
    margin: 0;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    padding: 0 14px;
    &:hover, &:active, &:focus {
        border: 1px solid #ed8154;
        outline: 1px solid #ed8154;
    }
`;

const Permission = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const permissionGroupData = useSelector((state) => state.Permissions.permissionGroupData);
    const [expandPanels, setExpandPanels] = useState(true);

    const [expandedProjects, setExpandedProjects] = useState(true);
    const [expandedSurveys, setExpandedSurveys] = useState(true);
    const toggleExpandedProjects = () => setExpandedProjects(!expandedProjects);
    const toggleExpandedSurveys = () => setExpandedSurveys(!expandedSurveys);

    useEffect(() => { dispatch(fetchPermissionGroup(id)); }, []);

    const toggleExpandPanels = () => setExpandPanels(!expandPanels);

    const onChange = event => {
        const VALUES = {
            full: true,
            none: false,
        };

        if (event.target.dataset.permission) {
            dispatch(editPermissionGroup({
                id: id,
                target: event.target.dataset.permission,
                value: VALUES[event.target.value],
            }));
        }
    }

    const avaialblePermissions = permissionGroupData?.avaialblePermissions;
    const access = permissionGroupData.access;

    return (
        <Wrapper>
            <WrapperHead>
                <Breadcrumb primary>
                    <BreadcrumbLink to={"/home"}><BreadcrumbItem children={<HomeIcon />} /></BreadcrumbLink>
                    <BreadcrumbLink to={"/company-settings/overview"}><BreadcrumbItem children={"Setting"} /></BreadcrumbLink>
                    <BreadcrumbLink to={"/company-settings/permission-group"}><BreadcrumbItem children={"Permission Group"} /></BreadcrumbLink>
                    <BreadcrumbLink to={"#"/*`/company-settings/permission-group/permission/${id}`*/}><BreadcrumbItem children={"Permission"} /></BreadcrumbLink>
                </Breadcrumb>
            </WrapperHead>

            <PermissionTable>
                <UserBlockList>
                    Edit Access: <span>{permissionGroupData.name ?? ""}</span>
                </UserBlockList>
                <DataTable>
                    <table style={{ border: "1px solid #ed8154", width: "100%" }}>
                        <thead>
                            <tr>
                                <th>System</th>
                                <th>Access Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ShowIf condition={avaialblePermissions?.projects}>
                                <tr >
                                    <td>
                                        <ExpandButton onClick={toggleExpandedProjects} open={expandedProjects && access?.projects} disabled={!access?.projects}>
                                            Projects
                                            <ShowIf condition={access?.projects}>
                                                <DownIcon />
                                            </ShowIf>
                                        </ExpandButton>
                                    </td>
                                    <td>
                                        <Select onChange={onChange} value={access?.projects ? "full" : "none"} data-permission="projects">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.projectsGeneralSettings && access?.projects && expandedProjects}>
                                <tr >
                                    <td><div style={{ marginLeft: "24px" }}>Projects - Global Settings</div></td>
                                    <td>
                                        <Select onChange={onChange} value={access?.projectsGeneralSettings ? "full" : "none"} data-permission="projectsGeneralSettings">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                                <tr >
                                    <td><div style={{ marginLeft: "24px" }}>Projects - Delete</div></td>
                                    <td>
                                        <Select onChange={onChange} value={access?.projectsDelete ? "full" : "none"} data-permission="projectsDelete">
                                            <option value="full">Enable</option>
                                            <option value="none">Disable</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.surveyDesigner}>
                                <tr >
                                    <td>
                                        <ExpandButton onClick={toggleExpandedSurveys} open={expandedSurveys && access?.surveys} disabled={!access?.surveys}>
                                            Surveys
                                            <ShowIf condition={access?.surveys}>
                                                <DownIcon />
                                            </ShowIf>
                                        </ExpandButton>
                                    </td>
                                    <td>
                                        <Select onChange={onChange} value={access?.surveys ? "full" : "none"} data-permission="surveys">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={access?.surveys && avaialblePermissions?.surveyDesigner && expandedSurveys}>
                                <tr >
                                    <td><div style={{ marginLeft: "24px" }}>Survey Designer</div></td>
                                    <td>
                                        <Select onChange={onChange} value={access?.surveyDesigner ? "full" : "none"} data-permission="surveyDesigner">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.scheduler}>
                                <tr >
                                    <td>Scheduler</td>
                                    <td>
                                        <Select onChange={onChange} value={access?.scheduler ? "full" : "none"} data-permission="scheduler">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.panels}>
                                <tr >
                                    <td>
                                        <ExpandButton onClick={toggleExpandPanels} open={expandPanels}>
                                            Panels
                                            <ShowIf condition={access?.panels}>
                                                <DownIcon style={{ marginLeft: "10px" }} />
                                            </ShowIf>
                                        </ExpandButton>
                                    </td>
                                    <td>
                                        <Select onChange={onChange} value={access?.panels ? "full" : "none"} data-permission="panels">
                                            <option value="full">Enable</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>

                                <ShowIf condition={access?.panels && expandPanels}>
                                    <tr >
                                        <td><div style={{ marginLeft: "24px" }}>Create Panels</div></td>
                                        <td>
                                            <Select onChange={onChange} value={access?.createPanels ? "full" : "none"} data-permission="createPanels">
                                                <option value="full">Enable</option>
                                                <option value="none">Disable</option>
                                            </Select>
                                        </td>
                                    </tr>

                                    {permissionGroupData.panelList?.map(
                                        panel => <PanelGroup panel={panel} access={access} permissionGroupId={id} key={panel._id} />
                                    )}
                                </ShowIf>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.contacts}>
                                <tr >
                                    <td>Contacts</td>
                                    <td>
                                        <Select onChange={onChange} value={access?.contacts ? "full" : "none"} data-permission="contacts">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.defaults}>
                                <tr >
                                    <td>Defaults</td>
                                    <td>
                                        <Select onChange={onChange} value={access?.defaults ? "full" : "none"} data-permission="defaults">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>

                            <ShowIf condition={avaialblePermissions?.settings}>
                                <tr >
                                    <td>Settings</td>
                                    <td>
                                        <Select onChange={onChange} value={access?.settings ? "full" : "none"} data-permission="settings">
                                            <option value="full">Full Access</option>
                                            <option value="none">No Access</option>
                                        </Select>
                                    </td>
                                </tr>
                            </ShowIf>
                        </tbody>
                    </table>
                </DataTable>
            </PermissionTable>
        </Wrapper>
    );
};

const PanelGroup = ({ panel, access, permissionGroupId }) => {
    const dispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);

    const panelId = panel._id;
    const panelAccess = access?.panelMap?.[panelId];


    const onChange = event => {
        const VALUES = {
            full: true,
            none: false,
        };

        if (event.target.dataset.permission) {
            dispatch(editPermissionGroup({
                id: permissionGroupId,
                panelId: panelId,
                target: "panel",
                field: event.target.dataset.permission,
                value: VALUES[event.target.value],
            }));
        }
    }

    return (
        <>
            <tr>
                <td style={{ paddingLeft: "40px" }}>
                    <ExpandButton onClick={toggleExpanded} open={expanded && panelAccess?.access} disabled={!panelAccess?.access}>
                        Panels - {panel.name}
                        <ShowIf condition={panelAccess?.access}>
                            <DownIcon />
                        </ShowIf>
                    </ExpandButton>
                </td>
                <td>
                    <Select onChange={onChange} value={panelAccess?.access ? "full" : "none"} data-permission="access">
                        <option value="full">Enable</option>
                        <option value="none">No Access</option>
                    </Select>
                </td>
            </tr>

            <ShowIf condition={panelAccess?.access && expanded}>
                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Join Form</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.joinForm ? "full" : "none"} data-permission="joinForm">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Panel Export</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.export ? "full" : "none"} data-permission="export">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Import & Update Data</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.update ? "full" : "none"} data-permission="update">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Members</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.members ? "full" : "none"} data-permission="members">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Bulk Edit</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.bulkEdit ? "full" : "none"} data-permission="bulkEdit">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Financials</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.financials ? "full" : "none"} data-permission="financials">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Settings</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.settings ? "full" : "none"} data-permission="settings">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Project Access</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.projectAccess ? "full" : "none"} data-permission="projectAccess">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - Export PII Access</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.piiExportAccess ? "full" : "none"} data-permission="piiExportAccess">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr>

                {/* <tr >
                    <td style={{ paddingLeft: "80px" }}>Panels - PAM Access</td>
                    <td>
                        <Select onChange={onChange} value={panelAccess?.pamAccess ? "full" : "none"} data-permission="pamAccess">
                            <option value="full">Full Access</option>
                            <option value="none">No Access</option>
                        </Select>
                    </td>
                </tr> */}
            </ShowIf>
        </>
    )
}

const ExpandButton = styled.button`
    background: transparent;
    border: none;
    padding: 10px 0;
    font-size: 14px !important;
    color: #828282 !important;
    svg {
        height:8px !important;
        margin-left: 10px;
        ${props => props.open ? "transform: rotate(180deg);" : ""} 
    }
    path {
        fill: #828282;
    }
    &:disabled {
        color: #828282 !important;
        cursor: text !important;
        opacity: 1;
    }
`;

export default Permission;
