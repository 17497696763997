import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { fetchPermissionGroups } from "../../../redux/features/PermissionSlice";
import { updateUser } from "../../../redux/features/UserManagementSlice";
import Button from "../../ui/Button";

import { ReactComponent as Close } from "../../ui/styles/Icon_Close.svg";

const ExportModalCard = styled.div`
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 12%);
    // overflow: hidden;
    width: 750px;
`;

const ModalCardBody = styled.div`
    padding: 22px;
    margin: 0;
    list-style-type: none;
    background: #fff;
    ul {
        padding: 0;
        list-style-type: none;
        text-align: left;
        margin: 10px 0;
        li {
            font-size: 14px;
            font-weight: 400;
            color: #999;
            line-height: 20px;
            span {
            font-weight: 700;
        }
    }
`;

const ModalCardHead = styled.div`
    border-bottom: 1px solid #ed8154;
    background: #ed8154;
    padding: 8px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
        font-size: 16px !important;
        color: #fff !important;
        margin: 0;
        font-weight: 600;
    }

    button {
        padding: 0;
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const ExportModalCardForm = styled.div`
    width: 100%;
    input {
        width: 100%;
        border: 1px solid #ddd;
        min-height: 40px;
        box-sizing: border-box;
        padding: 8px 12px;
        border-radius: 8px;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    label {
        font-size: 14px !important;
        color: #666 !important;
        margin: 0;
        width: 100%;
        text-align: left;
        display: block;
        margin-bottom: 6px;
        font-weight: 700;
    }
`;

const InputBox = styled.div`
    margin-bottom: 20px;
    & > div > div {
        border-color: #ddd;
    }
`;

const ExportModalCardButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 22px;

  button {
    font-size: 14px;
    color: #fff;
    background: #ed8154;
    border: 1px solid #ed8154;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px 20px;
    min-width: 95px;
    margin-left: 12px;
    font-weight: 600;
    &:hover {
        background-color:rgb(243, 147, 106);
    }
    &:first-of-type {
        margin-left: 0;
        background: #fff;
        color: #ed8154;
        &:hover {
            background-color:rgb(250, 240, 236);
        }
    }
  }
`;

const Select = styled.select`
    min-width: 300px;
    margin: 0;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
    padding: 0 14px;
    &:hover, &:active, &:focus {
        border: 1px solid #ed8154;
        outline: 1px solid #ed8154;
    }
`;

const SelectPermission = ({ setSelectPermissionModal, setPermissionUser, permissionUser }) => {
    const dispatch = useDispatch();

    const permissionGroupList = useSelector(state => state.Permissions.permissionGroupList);
    const [updatedPermissionGroupId, setUpdatedPermissionGroupId] = useState("");

    useEffect(() => { dispatch(fetchPermissionGroups()); }, [dispatch]);
    useEffect(() => { setUpdatedPermissionGroupId(permissionUser.permissionGroupId) }, [permissionUser.permissionGroupId]);

    const handleSavePermissionGroup = () => {
        if (updatedPermissionGroupId) {
            var editableValue = {
                ...permissionUser,
                permissionGroupId: updatedPermissionGroupId,
            };
            dispatch(updateUser(editableValue));
            setSelectPermissionModal(false);
        }
    };

    const onChange = event => setUpdatedPermissionGroupId(event.target.value);

    return (
        <React.Fragment>
            <ExportModalCard>
                <ModalCardHead>
                    <h6>Permission Group</h6>
                    <Button>
                        <Close onClick={() => setSelectPermissionModal(false)} />
                    </Button>
                </ModalCardHead>
                <ModalCardBody>
                    <ExportModalCardForm>
                        <Select onChange={onChange} value={updatedPermissionGroupId}>
                            {permissionGroupList?.map(permission => (
                                <option value={permission._id} key={permission._id}>{permission.name}</option>
                            ))}
                        </Select>
                    </ExportModalCardForm>
                    <ExportModalCardButtons>
                        <Button onClick={() => setSelectPermissionModal(false)}>
                            Close
                        </Button>
                        <Button onClick={handleSavePermissionGroup}>Save</Button>
                    </ExportModalCardButtons>
                </ModalCardBody>
            </ExportModalCard>
        </React.Fragment>
    );
};

export default SelectPermission;
